import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"

import PhotoSection from "../components/photosection"

const Photo = () => {
  return (
    <Layout>
      <Head title="Photo" />
      <PhotoSection />
    </Layout>
  )
}

export default Photo
