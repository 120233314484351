import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as blogStyles from "./blog.module.scss"

const PhotoSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulImage(sort: { fields: date, order: DESC }) {
        edges {
          node {
            image {
              description
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={`${blogStyles.blogDiv}`}>
      {data.allContentfulImage != null &&
        data.allContentfulImage.edges.map(edge => {
          return (
            <div className={`${blogStyles.blogPost}`}>
              <img
                src={edge.node.image.file.url}
                alt={edge.node.image.description}
              />
            </div>
          )
        })}
    </div>
  )
}

export default PhotoSection
